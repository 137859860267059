<template>
  <div>
    <div class="title">{{$t('baseInfo')}}</div>
    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      :label-width="language === 'zh-cn' ? '200px' : '285px'"
    >
      <el-form-item :label="$t('investorType')" prop="investorType" style="width: 100%;">
        <el-radio-group v-model="form.investorType" @change="changeRadioIn1">
          <el-radio
            :label="symbol.ORGANIZATION.value"
          >{{ $t('investorTypeRadio.institutionalInvestor') }}</el-radio>
          <el-radio :label="symbol.PERSONAL.value">{{ $t('investorTypeRadio.individualInvestor') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('investorName')" prop="investorName" style="width: 100%;">
        <el-input
          v-model="form.investorName"
          :placeholder="$t('formPlaceHolder.phInvestorName')"
          style="width: 40%;"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('investorEMail')" prop="investorEmail" style="width: 100%;">
        <el-input
          v-model="form.investorEmail"
          :placeholder="$t('formPlaceHolder.phInvestorEmail')"
          style="width: 40%;"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('investorMobilePhoneNumber')"
        prop="investorMobileNumber"
        style="width: 100%;"
      >
        <el-input
          v-model="form.investorMobileNumber"
          :placeholder="$t('formPlaceHolder.phInvestorPhoneNumber')"
          style="width: 40%;"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('idCardOrCompanyCode')" prop="cardId" style="width: 100%;">
        <el-input
          v-model="form.cardId"
          :placeholder="$t('formPlaceHolder.phIdCardOrCompanyCode')"
          style="width: 40%;"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('supportingDocumentationFile')" required style="width: 100%;">
        <el-upload
          class="upload-demo"
          action="/api/v1/upload/file"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG, .pdf, .PDF"
          :on-remove="handleRemove1"
          :before-remove="beforeRemove"
          :on-success="onSuccess1"
          multiple
          :headers="{ token: this.$store.getters['auth/token'] }"
          :limit="5"
          :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">{{$t('fileUpLoad')}}</el-button>
          <span class="form-placeholder">{{ $t('formPlaceHolder.phEvaluationOperatingLicenses') }}</span>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('bankInformation')}}</div>
    <el-form
      ref="formBank"
      :model="formBank"
      :rules="rules"
      style="width: 1100px"
      :label-width="language === 'zh-cn' ? '200px' : '285px'"
    >
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" prop="areaId">
                <el-select v-model="bankCountryCity" :placeholder="$t('formPlaceHolder.phSelect')" style="width: 100%"
                  @change="changeCountry"
                >
                    <template v-if="language==='zh-cn'">
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.cnName"
                                :value="item.cnName">
                        </el-option>
                    </template>
                    <template v-else>
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.enName"
                                :value="item.enName">
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" prop="receivingBank">
            <el-select v-if="optionsBank.length" v-model="bankName" :placeholder="$t('formPlaceHolder.phReceivingBankSel')" style="width: 100%"
                       @change="changeBank" ref="select"
            >
                <template v-if="language==='zh-cn'">
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.cnName"
                            :value="item.cnName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.cnName}}
                        </div>
                    </el-option>
                </template>
                <template v-else>
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.enName"
                            :value="item.enName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.enName}}
                        </div>
                    </el-option>
                </template>
            </el-select>
            <el-input v-else
                    v-model="formBank.receivingBank"
                    :placeholder="$t('formPlaceHolder.phReceivingBank')"
            ></el-input>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode">
            <el-input
                    v-model="formBank.swiftCode"
                    :placeholder="$t('companyCreate.phSwiftCode')"
                    maxlength="30"
            ></el-input>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
        <el-input
          v-model="formBank.bankAccountNumber"
          :placeholder="$t('companyCreate.phBankAccountNumber')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" prop="accountHolderName">
        <el-input
          v-model="formBank.accountHolderName"
          :placeholder="$t('companyCreate.phAccountHolderName')"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('investmentInformation')}}</div>
    <p class="tips">{{$t('investCreate.tips')}}</p>
    <el-form
      ref="form3"
      :model="form3"
      :rules="rules"
      style="width: 1100px"
      :label-width="language === 'zh-cn' ? '200px' : '285px'"
    >
      <el-form-item
        :label="$t('targetTotalInvestmentSize')"
        prop="totalInvestmentSize"
        style="width: 100%;"
      >
        <el-input
          v-model="form3.totalInvestmentSize"
          :placeholder="$t('formPlaceHolder.phTargetTotal')"
          style="width: calc(40% - 65px);"
        ></el-input>
        <span>&nbsp;&nbsp;{{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('riskTolerance')" prop="riskTolerance" style="width: 100%;">
        <el-radio-group v-model="form3.riskTolerance" @change="changeRadioIn2">
          <el-radio
            :label="risk.KEEP.value"
            style="width: 100px;"
          >{{ $t('riskToleranceRadio.conservative') }}</el-radio>
          <el-radio
            :label="risk.PRUDENT.value"
            style="width: 100px;"
          >{{ $t('riskToleranceRadio.cautious') }}</el-radio>
          <el-radio
            :label="risk.STEADY.value"
            style="width: 100px;"
          >{{ $t('riskToleranceRadio.steady') }}</el-radio>
          <el-radio
            :label="risk.ACTIVE.value"
            style="width: 100px;"
          >{{ $t('riskToleranceRadio.positive') }}</el-radio>
          <el-radio
            :label="risk.RADICAL.value"
            style="width: 100px;"
          >{{ $t('riskToleranceRadio.radical') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('experienceInSupplyChainInvestment')"
        prop="invertmentYears"
        style="width: 100%;"
      >
        <el-radio-group v-model="form3.invertmentYears" @change="changeRadioIn3">
          <el-radio
            :label="experience.NONE.value"
            style="width: 100px;"
          >{{ $t('experienceInSupplyChainInvestmentRadio.none') }}</el-radio>
          <el-radio
            :label="experience.ONE_TO_THREE.value"
            style="width: 100px;"
          >{{ $t('experienceInSupplyChainInvestmentRadio.otYears') }}</el-radio>
          <el-radio
            :label="experience.THREE_TO_FIVE.value"
            style="width: 100px;"
          >{{ $t('experienceInSupplyChainInvestmentRadio.tfYears') }}</el-radio>
          <el-radio
            :label="experience.FIVE_TO_TEN.value"
            style="width: 100px;"
          >{{ $t('experienceInSupplyChainInvestmentRadio.ftYears') }}</el-radio>
          <el-radio
            :label="experience.MORE_TEN.value"
            style="width: 100px;"
          >{{ $t('experienceInSupplyChainInvestmentRadio.aboveYears') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="submitClick()">{{$t('submit')}}</el-button>
      <el-button class="btn-gray">{{$t('btnI18n.Cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import { getObjByKeyValue } from "@/utils/index";

export default {
  name: "invest-create",
  data() {
    return {
      symbol: this.$enums.INVESTORTYPE,
      risk: this.$enums.RISKTOLERANCE,
      experience: this.$enums.EXPERIENCEINVESTMENT,
      form: {
        investorType: "",
        investorName: "",
        investorEmail: "",
        investorMobileNumber: "",
        cardId: ""
      },
        // bank update
        optionsCountry: [],
        optionsBank: [],
        bankCountryCity: "",
        bankName: "",
        // bank update
      formBank: {
          areaId: "",
          areaBankId: "",
          receivingBank: "",
          receivingBankEn: "",
          swiftCode: "",
        receivingBankIcon: "",
        bankAccountNumber: "",
        accountHolderName: "",
      },
      form3: {
        invertmentYears: "",
        riskTolerance: "",
      },
      rules: {
        cardId: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        supportingDocument: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        totalInvestmentSize: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        invertmentYears: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        experienceType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        riskTolerance: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        riskType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        investorType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        symbolType: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        investorMobileNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        investorEmail: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        accountHolderName: [{ required: true, message: this.$t('companyCreate.userCannotEmpty'), trigger: 'blur' }],
          bankAccountNumber: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
          areaId: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
        receivingBank: [
            { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'blur' },
            { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'change' }
        ],
        investorName: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        swiftCode: [{ required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'blur' },
            { required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'change' }]
      },
      fileList: [],
      headers: {}
    };
  },
  mounted() {
    this.headers = { address: this.$store.getters['auth/user'].authorization, type: this.$store.getters['auth/user'].type };
    this.getAreaList();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getBankList(code) {
          let _this = this;
          this.optionsBank = [];
          this.$axios.get("/v1/bank/list", {params: { areaCode: code.toString() || "86" }}).then(res => {
              if (!res.code) {
                  _this.optionsBank = res.data;
              }
          });
      },
      // bank update
    handleRemove1(file, fileList) {
      let dataArr = [];
      fileList.map((file) => {
        if (file.response) {
          dataArr.push(file.response.data);
        }
      });
      this.form.supportingDocument = JSON.stringify(dataArr);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: this.$t('companyCreate.limitedSelected5'),
        type: 'warning'
      });
      // this.$message({
      //   message: this.$t('companyCreate.limitedSelected') + this.$t('iChose') + `${files.length}` + this.$t('filesNumber') + this.$t('selected') + `${files.length + fileList.length}` + this.$t('filesNumber'),
      //   type: 'warning'
      // });
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
    },
    onSuccess1(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
        this.form.supportingDocument = JSON.stringify(dataArr);
      }
    },
    changeRadioIn1(e) {
      this.form.investorType = e;
    },
    changeRadioIn2(e) {
      this.form3.riskTolerance = e;
    },
    changeRadioIn3(e) {
      this.form3.invertmentYears = e;
    },
      // bank update
      changeCountry(e) {
        this.bankName = "";
        this.formBank.receivingBank = "";
          this.formBank.receivingBankEn = "";
          this.formBank.swiftCode = "";
          this.formBank.receivingBankIcon = "";
        const key = this.language === "zh-cn" ? "cnName" : "enName";
        const {code} = getObjByKeyValue(this.optionsCountry, key, e);
        this.formBank.areaId = code.toString();
        this.getBankList(code);
      },
      changeBank(e) {
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {swiftCode, icon, cnName, enName, id} = getObjByKeyValue(this.optionsBank, key, e);
          this.formBank.areaBankId = id;
          this.formBank.receivingBank = cnName;
          this.formBank.receivingBankEn = enName;
          this.formBank.swiftCode = swiftCode;
          this.formBank.receivingBankIcon = icon;
          this.$refs.select.$el.children[0].children[0].setAttribute('style',"background:url("+ icon +") no-repeat 10px;background-size: 20px 20px;color:#333;padding-left: 45px;");
      },
      // bank update
    submitClick() {
      let submit = true;
      if (!this.form.supportingDocument || this.form.supportingDocument === '' || this.form.supportingDocument === undefined) {
        submit = false;
        return this.$message.error(this.$t('plaseUpload'));
      }
      if (this.form.investorEmail && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ig.test(this.form.investorEmail)) {
        this.$message({
          message: this.$t(`formValidate.emailError`),
          type: 'warning'
        });
        return false;
      }
      submit && this.$refs.form.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.formBank.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.form3.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      if (submit) {
        let info = Object.assign({}, this.form, this.formBank, this.form3);
        info.totalInvestmentSize = ((info.totalInvestmentSize) * this.$enums.UNIT_MILLION).toString();
        this.$axios.post('/v1/invest/detail', info).then(result => {
          if (result.code === 0 || result.code === '0') {
            this.$message({ type: 'success', message: this.$t('submittedSuccessfully') });
            this.$router.push({ path: '/review' });
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        });
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
}
.tips {
  font-size: 18px;
  font-family: "Arial-ItalicMT";
  color: #909090;
  margin-left: 20px;
}
</style>
